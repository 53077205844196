<div class="container-fluid">
  <app-pagetitle title="Add Product" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div id="addproduct-nav-pills-wizard" class="twitter-bs-wizard">
            <ng-stepper #cdkStepper [linear]="true" class="twitter-bs-wizard-nav nav nav-pills nav-justified">
                <cdk-step [optional]="false" class="nav-item">
                  <ng-template cdkStepLabel>
                    <a href="javascript:void(0)" class="nav-link active" data-bs-toggle="tab">
                        <span class="step-number">01</span>
                        <span class="step-title">Basic Info</span>
                    </a>
                    </ng-template>
                    <section *ngIf="cdkStepper.selectedIndex === 0">
                      <div class="tab-pane active" id="basic-info">
                        <h4 class="card-title">Basic Information</h4>
                        <p class="card-title-desc">Fill all information below</p>
        
                        <form>
                          <div class="mb-3">
                            <label for="productname">Product Name</label>
                            <input id="productname" name="productname" type="text" class="form-control">
                          </div>
                          <div class="row">
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="manufacturername">Manufacturer Name</label>
                                <input id="manufacturername" name="manufacturername" type="text" class="form-control">
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="manufacturerbrand">Manufacturer Brand</label>
                                <input id="manufacturerbrand" name="manufacturerbrand" type="text" class="form-control">
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="price">Price</label>
                                <input id="price" name="price" type="text" class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="mb-3">
                                <label class="control-label">Category</label>
                                <select class="form-control select2">
                                  <option>Select</option>
                                  <option value="EL">Electronic</option>
                                  <option value="FA">Fashion</option>
                                  <option value="FI">Fitness</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="mb-3">
                                <label class="control-label">Features</label>
                                <ng-select [items]="selectValue" [multiple]="true"></ng-select>
                              </div>
                            </div>
                          </div>
                          <div class="mb-3">
                            <label for="productdesc">Product Description</label>
                            <textarea class="form-control" id="productdesc" rows="5"></textarea>
                          </div>
                        </form>
                    </div>
                    <button type="button" class="btn btn-primary">Previous</button>
                      <button type="button" class="btn btn-primary float-end" cdkStepperNext>Next</button>
                    </section>
                </cdk-step>
                <cdk-step [optional]="true" class="nav-item">
                  <ng-template cdkStepLabel>
                    <a href="javascript:void(0)" class="nav-link" data-bs-toggle="tab">
                        <span class="step-number">02</span>
                        <span class="step-title">Product Img</span>
                    </a>
                    </ng-template>
                    <section *ngIf="cdkStepper.selectedIndex === 1">
                      <div class="tab-pane mb-3" id="product-img">
                        <h4 class="card-title">Product Images</h4>
                        <p class="card-title-desc">Upload product image</p>
                        <dropzone class="dropzone"></dropzone>
                      </div>
                      <button type="button" class="btn btn-primary" cdkStepperPrevious>Previous</button>
                      <button type="button" class="btn btn-primary float-end" cdkStepperNext>Next</button>
                    </section>
                </cdk-step>
    
                <cdk-step [optional]="true" class="nav-item">
                  <ng-template cdkStepLabel>
                    <a href="javascript:void(0)" class="nav-link" data-bs-toggle="tab">
                        <span class="step-number">03</span>
                        <span class="step-title">Meta Data</span>
                    </a>
                  </ng-template>
                  <section *ngIf="cdkStepper.selectedIndex === 2">
                    <div class="tab-pane" id="metadata">
                      <h4 class="card-title">Meta Data</h4>
                      <p class="card-title-desc">Fill all information below</p>
      
                      <form>
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="metatitle">Meta title</label>
                              <input id="metatitle" name="metatitle" type="text" class="form-control">
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="metakeywords">Meta Keywords</label>
                              <input id="metakeywords" name="metakeywords" type="text" class="form-control">
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="metadescription">Meta Description</label>
                          <textarea class="form-control" id="metadescription" rows="5"></textarea>
                        </div>
                      </form>
                      <div class="text-center mt-4">
                        <button type="submit" class="btn btn-primary me-2 waves-effect waves-light">Save Changes</button>
                        <button type="submit" class="btn btn-light waves-effect">Cancel</button>
                      </div>
                    </div>
                    <div class="pager wizard twitter-bs-wizard-pager-link">
                      <button class="btn btn-primary" cdkStepperPrevious>Previous</button>
                      <button type="button" class="btn btn-primary float-end">Finish</button>
                    </div>
                  </section>
                </cdk-step>
            </ng-stepper>
            <!-- end ul -->
        </div>

        </div>
      </div>
    </div>
  </div>
</div>