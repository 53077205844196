<div class="container-fluid">
  <app-pagetitle title="Shops" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <div class="row">
    @for(data of shopsData;track $index){
    <div class="col-xl-3 col-sm-6">
      <div class="card">
        <div class="card-body">
          <div class="text-center">
            <img src="{{data.image}}" alt class="avatar-sm mt-2 mb-4" />
            <div class="flex-1">
              <h5 class="text-truncate">
                <a href="javascript: void(0);" class="text-dark">{{data.title}}</a>
              </h5>
              <p class="text-muted">
                <i class="mdi mdi-account me-1"></i>
                {{data.name}}
              </p>
            </div>
          </div>
          <hr class="my-4" />
          <div class="row text-center">
            <div class="col-6">
              <p class="text-muted mb-2">Products</p>
              <h5>{{data.products}}</h5>
            </div>
            <div class="col-6">
              <p class="text-muted mb-2">Wallet Balance</p>
              <h5>{{data.balance}}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
  </div>
  <!-- end row -->

  <div class="row">
    <div class="col-xl-12">
      <div class="text-center my-3">
        <a href="javascript:void(0);" class="text-primary">
          <i class="mdi mdi-loading mdi-spin font-size-20 align-middle me-2"></i> Load more
        </a>
      </div>
    </div>
  </div>
  <!-- end row -->
</div>
