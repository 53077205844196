import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';
import {latLng, tileLayer} from 'leaflet';


import {ChartType, Stat, Chat, Transaction} from './dashboard.model';

import {
  statData,
  revenueChart,
  salesAnalytics,
  sparklineEarning,
  sparklineMonthly,
  chatData,
  transactions,
  spark3Chart,
  spark2Chart,
  spark1Chart
} from './data';
import {Router} from "@angular/router";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

/**
 * Dashboard Component
 */
export class DashboardComponent implements OnInit {

  term: any;
  chatData: Chat[];
  transactions: Transaction[];
  statData: Stat[];

  constructor(public formBuilder: UntypedFormBuilder, private router: Router) {
  }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  revenueChart: ChartType;
  salesAnalytics: ChartType;
  sparklineEarning: ChartType;
  sparklineMonthly: ChartType;
  spark1: any;
  spark2: any;
  spark3: any;

  // Form submit
  chatSubmit: boolean;

  formData: UntypedFormGroup;


  options = {
    layers: [
      tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {maxZoom: 18, attribution: '...'})
    ],
    zoom: 6,
    center: latLng(46.879966, -121.726909)
  };

  ngOnInit(): void {
    if (!localStorage.getItem('token')) {
      this.router.navigate(['/pages/login-1']).then(() => {
        localStorage.clear()
      })
    }
    this.breadCrumbItems = [{label: 'Nazox'}, {label: 'Dashboard', active: true}];
    this.formData = this.formBuilder.group({
      message: ['', [Validators.required]],
    });
    this._fetchData();
  }

  private _fetchData() {
    this.revenueChart = revenueChart;
    this.salesAnalytics = salesAnalytics;
    this.sparklineEarning = sparklineEarning;
    this.sparklineMonthly = sparklineMonthly;
    this.chatData = chatData;
    this.transactions = transactions;
    this.statData = statData;
    this.spark1 = spark1Chart;
    this.spark2 = spark2Chart;
    this.spark3 = spark3Chart;
  }

  /**
   * Returns form
   */
  get form() {
    return this.formData.controls;
  }

  /**
   * Save the message in chat
   */
  messageSave() {
    const message = this.formData.get('message').value;
    const currentDate = new Date();
    if (this.formData.valid && message) {
      // Message Push in Chat
      this.chatData.push({
        align: 'right',
        name: 'Ricky Clark',
        message,
        time: currentDate.getHours() + ':' + currentDate.getMinutes()
      });

      // Set Form Data Reset
      this.formData = this.formBuilder.group({
        message: null
      });
    }

    this.chatSubmit = true;
  }

}
