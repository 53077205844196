<div class="container-fluid">

  <!-- start page title -->
  <app-pagetitle title="Form Validation" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  <!-- end page title -->

  <div class="row">
    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Bootstrap Validation - Normal</h4>
          <p class="card-title-desc">Provide valuable, actionable feedback to your users with HTML5 form
            validation–available in all our supported browsers.</p>
          <form class="needs-validation" (ngSubmit)="validSubmit()" [formGroup]="validationform">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="validationCustom01">First name</label>
                  <input type="text" class="form-control" id="validationCustom01" formControlName="firstName" placeholder="First name" [ngClass]="{'is-valid': submit && form.firstName.errors}">
                  @if(submit && form.firstName.errors){
                  <div class="valid-feedback">
                    @if(form.firstName.errors.required){
                    <span>Looks good!</span>
                    }
                  </div>
                  }
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="validationCustom02">Last name</label>
                  <input type="text" class="form-control" id="validationCustom02" formControlName="lastName" placeholder="Last name" [ngClass]="{'is-valid': submit && form.lastName.errors}">
                  @if(submit && form.lastName.errors){
                  <div class="valid-feedback">
                    @if(form.lastName.errors.required){
                    <span>Looks good!</span>
                    }
                  </div>
                  }
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="validationCustom03">City</label>
                  <input type="text" class="form-control" id="validationCustom03" formControlName="city" placeholder="City" [ngClass]="{'is-invalid': submit && form.city.errors}">
                  @if(submit && form.city.errors){
                  <div class="invalid-feedback">
                    @if(form.city.errors.required){
                    <span>Please provide a valid city.</span>
                    }
                  </div>
                  }
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="validationCustom04">State</label>
                  <input type="text" class="form-control" id="validationCustom04" formControlName="state" placeholder="State" [ngClass]="{'is-invalid': submit && form.state.errors}">
                  @if(submit && form.state.errors){
                  <div class="invalid-feedback">
                    @if(form.state.errors.required){
                    <span>Please provide a valid state.</span>
                    }
                  </div>
                  }
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="validationCustom05">Zip</label>
                  <input type="text" class="form-control" id="validationCustom05" formControlName="zip" placeholder="Zip" [ngClass]="{'is-invalid': submit && form.zip.errors}">
                  @if(submit && form.zip.errors){
                  <div class="invalid-feedback">
                    @if(form.zip.errors.required){
                    <span>Please provide a valid zip.</span>
                    }
                  </div>
                  }
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <div class="form-check ps-0">
                    <input type="checkbox" class="form-control-input" id="invalidCheck">
                    <label class="form-check-label" for="invalidCheck">Agree to terms and conditions</label>
                    <div class="invalid-feedback">
                      You must agree before submitting.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button class="btn btn-primary" type="submit">Submit form</button>
          </form>
        </div>
      </div>
      <!-- end card -->
    </div> <!-- end col -->

    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Bootstrap Validation (Tooltips)</h4>
          <p class="card-title-desc">If your form layout allows it, you can swap the
            <code>.valid|invalid-feedback</code> classes for <code>.valid|invalid-tooltip</code> classes to display
            validation feedback in a styled tooltip.
          </p>
          <form class="needs-validation" (ngSubmit)="formSubmit()" [formGroup]="tooltipvalidationform">
            <div class="row">
              <div class="col-md-4">
                <div class="mb-3 position-relative">
                  <label for="validationTooltip01">First name</label>
                  <input type="text" class="form-control" formControlName="firstName" id="validationTooltip01" placeholder="First name" value="Mark" [ngClass]="{'is-valid': formsubmit && formData.firstName.errors}">
                  @if(formsubmit && formData.firstName.errors){
                  <div class="valid-tooltip">
                    @if(formData.firstName.errors.required){
                    <span>Looks good!</span>
                    }
                  </div>
                  }
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3 position-relative">
                  <label for="validationTooltip02">Last name</label>
                  <input type="text" class="form-control" id="validationTooltip02" formControlName="lastName" placeholder="Last name" value="Otto" [ngClass]="{'is-valid': formsubmit && formData.lastName.errors}">
                  @if(formsubmit && formData.lastName.errors){
                  <div class="valid-tooltip">
                    @if(formData.lastName.errors.required){
                    <span>Looks good!</span>
                    }
                  </div>
                  }
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3 position-relative">
                  <label for="validationTooltipUsername">Username</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="validationTooltipUsernamePrepend">&#64;</span>
                    </div>
                    <input type="text" class="form-control" id="validationTooltipUsername" formControlName="userName" placeholder="Username" aria-describedby="validationTooltipUsernamePrepend" [ngClass]="{'is-invalid': formsubmit && formData.userName.errors}">
                    @if(formsubmit && formData.userName.errors){
                    <div class="invalid-tooltip">
                      @if(formData.userName.errors.required){
                      <span>Please choose a unique and valid username.</span>
                      }
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3 position-relative">
                  <label for="validationTooltip03">City</label>
                  <input type="text" class="form-control" id="validationTooltip03" placeholder="City" formControlName="city" [ngClass]="{'is-invalid': formsubmit && formData.city.errors}">
                  @if(formsubmit && formData.city.errors){
                  <div class="invalid-tooltip">
                    @if(formData.city.errors.required){
                    <span>Please provide a valid city.</span>
                    }
                  </div>
                  }
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3 position-relative">
                  <label for="validationTooltip04">State</label>
                  <input type="text" class="form-control" id="validationTooltip04" placeholder="State" formControlName="state" [ngClass]="{'is-invalid': formsubmit && formData.state.errors}">
                  @if(formsubmit && formData.state.errors){
                  <div class="invalid-tooltip">
                    @if(formData.state.errors.required){
                    <span>Please provide a valid state.</span>
                    }
                  </div>
                  }
                </div>
              </div>
            </div>
            <button class="btn btn-primary" type="submit">Submit form</button>
          </form>
        </div>
      </div>
      <!-- end card -->
    </div> <!-- end col -->
  </div>
  <!-- end row -->

  <div class="row">
    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Validation type</h4>
          <p class="card-title-desc">
            Parsley is a javascript form validation library. It helps you provide your users with feedback on
            their form submission before sending it to your server.
          </p>
          <form (ngSubmit)="typeSubmit()" [formGroup]="typeValidationForm">
            <div class="mb-3">
              <label>Required</label>
              <input type="text" class="form-control" formControlName="text" [ngClass]="{'is-invalid': typesubmit && type.text.errors}" placeholder="Type something" />
              @if(typesubmit && type.text.errors){
              <div class="invalid-feedback">
                @if(type.text.errors.required){
                <span>This value is required.</span>
                }
              </div>
              }
            </div>
            <div class="mb-3">
              <label>Equal To</label>
              <div>
                <input type="password" class="form-control" placeholder="Password" formControlName="password" [ngClass]="{'is-invalid': typesubmit && type.password.errors}" />
                @if(typesubmit && type.password.errors){
                <div class="invalid-feedback">
                  @if(type.password.errors.required){
                  <span>This value is required.</span>
                  }
                  @if(type.password.errors.minlength){
                  <span>Password must be at least 6
                    characters.</span>
                  }
                </div>
                }
              </div>
              <div class="mt-2">
                <input type="password" class="form-control" placeholder="Re-Type Password" formControlName="confirmpwd" [ngClass]="{'is-invalid': typesubmit && type.confirmpwd.errors}" />
                @if(typesubmit && type.confirmpwd.errors){
                <div class="invalid-feedback">
                  @if(type.confirmpwd.errors.required){
                  <span>This value is required.</span>
                  }
                  @if(type.confirmpwd.errors.mustMatch){
                  <span>This value should be the same.</span>
                  }
                </div>
                }
              </div>
            </div>
            <div class="mb-3">
              <label>E-Mail</label>
              <div>
                <input type="email" class="form-control" formControlName="email" [ngClass]="{'is-invalid': typesubmit && type.email.errors}" placeholder="Enter a valid e-mail" />
                @if(typesubmit && type.email.errors){
                <div class="invalid-feedback">
                  @if(type.email.errors.required){
                  <span>This value is required.</span>
                  }
                  @if(type.email.errors.pattern){
                  <span>This value should be a valid email.</span>
                  }
                </div>
                }
              </div>
            </div>
            <div class="mb-3">
              <label>URL</label>
              <div>
                <input type="url" class="form-control" placeholder="URL" formControlName="url" [ngClass]="{'is-invalid': typesubmit && type.url.errors}" />
                @if(typesubmit && type.url.errors){
                <div class="invalid-feedback">
                  @if(type.url.errors.required){
                  <span>This value is required.</span>
                  }
                  @if(type.url.errors.pattern){
                  <span>This value should be a valid url.</span>
                  }
                </div>
                }
              </div>
            </div>
            <div class="mb-3">
              <label>Digits</label>
              <div>
                <input formControlName="digits" type="text" class="form-control" [ngClass]="{'is-invalid': typesubmit && type.digits.errors}" placeholder="Enter only digits" />
                @if(typesubmit && type.digits.errors){
                <div class="invalid-feedback">
                  @if(type.digits.errors.required){
                  <span>This value is required.</span>
                  }
                  @if(type.digits.errors.pattern){
                  <span>This value should be digits.</span>
                  }
                </div>
                }
              </div>
            </div>
            <div class="mb-3">
              <label>Number</label>
              <div>
                <input formControlName="number" type="text" class="form-control" [ngClass]="{'is-invalid': typesubmit && type.number.errors}" placeholder="Enter only numbers">
                @if(typesubmit && type.number.errors){
                <div class="invalid-feedback">
                  @if(type.number.errors.required){
                  <span>This value is required.</span>
                  }
                  @if(type.number.errors.pattern){
                  <span>This value should be a valid number.</span>
                  }
                </div>
                }
              </div>
            </div>
            <div class="mb-3">
              <label>Alphanumeric</label>
              <div>
                <input formControlName="alphanum" type="text" class="form-control" [ngClass]="{'is-invalid': typesubmit && type.alphanum.errors}" placeholder="Enter alphanumeric value" />
                @if(typesubmit && type.alphanum.errors){
                <div class="invalid-feedback">
                  @if(type.alphanum.errors.required){
                  <span>This value is required.</span>
                  }
                  @if(type.alphanum.errors.pattern){
                  <span>This value should be alphanumeric.</span>
                  }
                </div>
                }
              </div>
            </div>
            <div class="mb-3">
              <label>Textarea</label>
              <div>
                <textarea formControlName="textarea" class="form-control" rows="5" [ngClass]="{'is-invalid': typesubmit && type.textarea.errors}"></textarea>
                @if(typesubmit && type.textarea.errors){
                <div class="invalid-feedback">
                  @if(type.textarea.errors.required){
                  <span>This value is required.</span>
                  }
                </div>
                }
              </div>
            </div>
            <div class="mb-3 mb-0">
              <div>
                <button type="submit" class="btn btn-primary me-1">
                  Submit
                </button>
                <button type="reset" class="btn btn-secondary">
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Range validation</h4>
          <p class="card-title-desc">
            Parsley is a javascript form validation library. It helps you provide your users with feedback on
            their form submission before sending it to your server.
          </p>
          <form (ngSubmit)="rangeSubmit()" [formGroup]="rangeValidationForm">
            <div class="mb-3">
              <label>Min Length</label>
              <div>
                <input type="text" class="form-control" formControlName="minlength" [ngClass]="{'is-invalid': rangesubmit && range.minlength.errors}" placeholder="Min 6 chars." />
                @if(rangesubmit && range.minlength.errors){
                <div class="invalid-feedback">
                  @if(range.minlength.errors.required){
                  <span>This value is required.</span>
                  }
                  @if(range.minlength.errors.minlength){
                  <span>This value is too short. It should have 6
                    characters or more.</span>
                  }
                </div>
              }
              </div>
            </div>
            <div class="mb-3">
              <label>Max Length</label>
              <div>
                <input type="text" class="form-control" formControlName="maxlength" [ngClass]="{'is-invalid': rangesubmit && range.maxlength.errors}" placeholder="Max 6 chars." />
                @if(rangesubmit && range.maxlength.errors){
                <div class="invalid-feedback">
                  @if(range.maxlength.errors.required){
                  <span>This value is required.</span>
                  }
                  @if(range.maxlength.errors.maxlength){
                  <span>This value is too long. It should have 6
                    characters or fewer.</span>
                  }
                </div>
              }
              </div>
            </div>
            <div class="mb-3">
              <label>Range Length</label>
              <div>
                <input type="text" class="form-control" formControlName="rangelength" [ngClass]="{'is-invalid': rangesubmit && range.rangelength.errors}" placeholder="Text between 5 - 10 chars length" />
                @if(rangesubmit && range.rangelength.errors){
                <div class="invalid-feedback">
                  @if(range.rangelength.errors.required){
                  <span>This value is required.</span>
                  }
                  @if(range.rangelength.errors.minlength){
                  <span>This value length is invalid. It should
                    be between 5 and 10 characters long.</span>
                  }
                  @if(range.rangelength.errors.maxlength){
                  <span>This value length is invalid. It should
                    be between 5 and 10 characters long.</span>
                  }
                </div>
              }
              </div>
            </div>
            <div class="mb-3">
              <label>Min Value</label>
              <div>
                <input type="text" class="form-control" formControlName="minvalue" [ngClass]="{'is-invalid': rangesubmit && range.minvalue.errors}" placeholder="Min value is 6" />
                @if(rangesubmit && range.minvalue.errors){
                <div class="invalid-feedback">
                  @if(range.minvalue.errors.required){
                  <span>This value is required.</span>
                  }
                  @if(range.minvalue.errors.min){
                  <span>This value should be greater than or equal to
                    6.</span>
                  }
                </div>
              }
              </div>
            </div>
            <div class="mb-3">
              <label>Max Value</label>
              <div>
                <input type="text" class="form-control" formControlName="maxvalue" [ngClass]="{'is-invalid': rangesubmit && range.maxvalue.errors}" placeholder="Max value is 6" />
                @if(rangesubmit && range.maxvalue.errors){
                <div class="invalid-feedback">
                  @if(range.maxvalue.errors.required){
                  <span>This value is required.</span>
                  }
                  @if(range.maxvalue.errors.max){
                  <span>This value should be lower than or equal to
                    6.</span>
                  }
                </div>
              }
              </div>
            </div>
            <div class="mb-3">
              <label>Range Value</label>
              <div>
                <input class="form-control" type="text range" formControlName="rangevalue" [ngClass]="{'is-invalid': rangesubmit && range.rangevalue.errors}" placeholder="Number between 6 - 100" />
                @if(rangesubmit && range.rangevalue.errors){
                <div class="invalid-feedback">
                  @if(range.rangevalue.errors.required){
                  <span>This value is required.</span>
                  }
                  @if(range.rangevalue.errors.min){
                  <span>This value should be between 6 and 100.</span>
                  }
                  @if(range.rangevalue.errors.max){
                  <span>This value should be between 6 and 100.</span>
                  }
                </div>
              }
              </div>
            </div>
            <div class="mb-3">
              <label>Regular Exp</label>
              <div>
                <input type="text" class="form-control" formControlName="regularexp" [ngClass]="{'is-invalid': rangesubmit && range.regularexp.errors}" placeholder="Hex. Color" />
                @if(rangesubmit && range.regularexp.errors){
                <div class="invalid-feedback">
                  @if(range.regularexp.errors.required){
                  <span>This value is required.</span>
                  }
                  @if(range.regularexp.errors.pattern){
                  <span>This value seems to be invalid.</span>
                  }
                </div>
              }
              </div>
            </div>

            <div class="mb-3 mb-0">
              <div>
                <button type="submit" class="btn btn-primary me-1">
                  Submit
                </button>
                <button type="reset" class="btn btn-secondary">
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>