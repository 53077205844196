<div class="container-fluid">
  <app-pagetitle title="Products" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <div class="row">
    <div class="col-xl-3 col-lg-4">
      <div class="card">
        <div class="card-header bg-transparent border-bottom">
          <h5 class="mb-0">Filters</h5>
        </div>

        <div class="card-body">
          <h5 class="font-size-14 mb-3">Categories</h5>
          <div ngbAccordion [closeOthers]="true" class="accordion ecommerce" id="accordionExample">
            <div ngbAccordionItem class="accordion-item">
              <h2 ngbAccordionHeader class="accordion-header" id="headingOne">
                <button ngbAccordionButton class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  <i class="mdi mdi-desktop-classic font-size-16 align-middle me-2"></i> Electronic
                </button>
              </h2>
              <div ngbAccordionCollapse id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div ngbAccordionBody class="accordion-body">
                  <ul class="list-unstyled categories-list mb-0">
                    <li><a href="javascript: void(0);"><i class="mdi mdi-circle-medium me-1"></i> Mobile</a></li>
                    <li><a href="javascript: void(0);"><i class="mdi mdi-circle-medium me-1"></i> Mobile accessories</a>
                    </li>
                    <li><a href="javascript: void(0);"><i class="mdi mdi-circle-medium me-1"></i> Computers</a></li>
                    <li><a href="javascript: void(0);"><i class="mdi mdi-circle-medium me-1"></i> Laptops</a></li>
                    <li><a href="javascript: void(0);"><i class="mdi mdi-circle-medium me-1"></i> Speakers</a></li>
                  </ul>

                </div>
              </div>
            </div>
            <div ngbAccordionItem class="accordion-item">
              <h2 ngbAccordionHeader class="accordion-header" id="headingTwo">
                <button ngbAccordionButton class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  <i class="mdi mdi-hanger font-size-16 align-middle me-2"></i> Fashion
                </button>
              </h2>
              <div ngbAccordionCollapse id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div ngbAccordionBody class="accordion-body">
                  <ul class="list-unstyled categories-list mb-0">
                    <li class="active"><a href="javascript: void(0);"><i class="mdi mdi-circle-medium me-1"></i>
                        Clothing</a></li>
                    <li><a href="javascript: void(0);"><i class="mdi mdi-circle-medium me-1"></i> Footwear</a></li>
                    <li><a href="javascript: void(0);"><i class="mdi mdi-circle-medium me-1"></i> Watches</a></li>
                    <li><a href="javascript: void(0);"><i class="mdi mdi-circle-medium me-1"></i> Sportswear</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div ngbAccordionItem class="accordion-item">
              <h2 ngbAccordionHeader class="accordion-header" id="headingThree">
                <button ngbAccordionButton class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  <i class="mdi mdi-pinwheel-outline font-size-16 align-middle me-2"></i> Baby & Kids
                </button>
              </h2>
              <div ngbAccordionCollapse id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div ngbAccordionBody class="accordion-body">
                  <ul class="list-unstyled categories-list mb-0">
                    <li><a href="javascript: void(0);"><i class="mdi mdi-circle-medium me-1"></i> Clothing</a></li>
                    <li><a href="javascript: void(0);"><i class="mdi mdi-circle-medium me-1"></i> Footwear</a></li>
                    <li><a href="javascript: void(0);"><i class="mdi mdi-circle-medium me-1"></i> Toys</a></li>
                    <li><a href="javascript: void(0);"><i class="mdi mdi-circle-medium me-1"></i> Baby care</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div ngbAccordionItem class="accordion-item mb-3">
              <h2 ngbAccordionHeader class="accordion-header" id="headingFour">
                <button ngbAccordionButton class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  <i class="mdi mdi-dumbbell font-size-16 align-middle me-2"></i> Fitness
                </button>
              </h2>
              <div ngbAccordionCollapse id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                <div ngbAccordionBody class="accordion-body">
                  <ul class="list-unstyled categories-list mb-0">
                    <li><a href="javascript: void(0);"><i class="mdi mdi-circle-medium me-1"></i> Gym equipment</a></li>
                    <li><a href="javascript: void(0);"><i class="mdi mdi-circle-medium me-1"></i> Yoga mat</a></li>
                    <li><a href="javascript: void(0);"><i class="mdi mdi-circle-medium me-1"></i> Dumbbells</a></li>
                    <li><a href="javascript: void(0);"><i class="mdi mdi-circle-medium me-1"></i> Protein supplements</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body border-top">
          <div>
            <h5 class="font-size-14 mb-4">Price</h5>
            <ngx-slider [(value)]="pricevalue" [options]="priceoption" [(highValue)]="maxVal" (valueChange)="valueChange($event,true)" (highValueChange)="valueChange($event,false)"></ngx-slider>
          </div>
        </div>

        <div class="custom-accordion">
          <div class="card-body border-top">
            <div>
              <h5 class="font-size-14 mb-0"><a class="text-dark d-block" [ngClass]="{'collapsed':isCollapsed}" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">Discount <i class="mdi mdi-minus float-end accor-plus-icon"></i></a></h5>
              <div id="collapseExample" [ngbCollapse]="isCollapsed">
                <div class="mt-4">
                  <div class="form-check mt-2">
                    <input type="radio" id="productdiscountRadio6" name="productdiscountRadio1" class="form-check-input">
                    <label class="form-check-label" for="productdiscountRadio6">50% or more</label>
                  </div>
                  <div class="form-check mt-2">
                    <input type="radio" id="productdiscountRadio5" name="productdiscountRadio1" class="form-check-input">
                    <label class="form-check-label" for="productdiscountRadio5">40% or more</label>
                  </div>
                  <div class="form-check mt-2">
                    <input type="radio" id="productdiscountRadio4" name="productdiscountRadio1" class="form-check-input">
                    <label class="form-check-label" for="productdiscountRadio4">30% or more</label>
                  </div>
                  <div class="form-check mt-2">
                    <input type="radio" id="productdiscountRadio3" name="productdiscountRadio1" class="form-check-input">
                    <label class="form-check-label" for="productdiscountRadio3">20% or more</label>
                  </div>
                  <div class="form-check mt-2">
                    <input type="radio" id="productdiscountRadio2" name="productdiscountRadio1" class="form-check-input">
                    <label class="form-check-label" for="productdiscountRadio2">10% or more</label>
                  </div>
                  <div class="form-check mt-2">
                    <input type="radio" id="productdiscountRadio1" name="productdiscountRadio1" class="form-check-input">
                    <label class="form-check-label" for="productdiscountRadio1">Less than 10%</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body border-top">
            <div>
              <h5 class="font-size-14 mb-0"><a class="text-dark d-block" [ngClass]="{'collapsed':collapsed}" (click)="collapsed = !collapsed" [attr.aria-expanded]="!collapsed" aria-controls="collapseExample2">Size <i class="mdi mdi-minus float-end accor-plus-icon"></i></a></h5>

              <div id="collapseExample2" [ngbCollapse]="collapsed">

                <div class="mt-4">
                  <div class="form-check mt-2">
                    <input type="radio" id="productsizeRadio1" name="productsizeRadio" class="form-check-input">
                    <label class="form-check-label" for="productsizeRadio1">X-Large</label>
                  </div>
                  <div class="form-check mt-2">
                    <input type="radio" id="productsizeRadio2" name="productsizeRadio" class="form-check-input">
                    <label class="form-check-label" for="productsizeRadio2">Large</label>
                  </div>
                  <div class="form-check mt-2">
                    <input type="radio" id="productsizeRadio3" name="productsizeRadio" class="form-check-input">
                    <label class="form-check-label" for="productsizeRadio3">Medium</label>
                  </div>
                  <div class="form-check mt-2">
                    <input type="radio" id="productsizeRadio4" name="productsizeRadio" class="form-check-input">
                    <label class="form-check-label" for="productsizeRadio4">Small</label>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="card-body border-top">
            <div>
              <h5 class="font-size-14 mb-0"><a (click)="collapsed3 = !collapsed3" [attr.aria-expanded]="!collapsed3" class="text-dark d-block" [ngClass]="{'collapsed':collapsed3}" aria-controls="collapseExample3">Customer Rating <i class="mdi mdi-minus float-end accor-plus-icon"></i></a>
              </h5>

              <div id="collapseExample3" [ngbCollapse]="collapsed3">
                <div class="mt-4">
                  <div class="form-check mt-2">
                    <input type="radio" id="productratingRadio1" name="productratingRadio1" class="form-check-input">
                    <label class="form-check-label" for="productratingRadio1">4 <i class="mdi mdi-star text-warning"></i> & Above</label>
                  </div>
                  <div class="form-check mt-2">
                    <input type="radio" id="productratingRadio2" name="productratingRadio1" class="form-check-input">
                    <label class="form-check-label" for="productratingRadio2">3 <i class="mdi mdi-star text-warning"></i> & Above</label>
                  </div>
                  <div class="form-check mt-2">
                    <input type="radio" id="productratingRadio3" name="productratingRadio1" class="form-check-input">
                    <label class="form-check-label" for="productratingRadio3">2 <i class="mdi mdi-star text-warning"></i> & Above</label>
                  </div>
                  <div class="form-check mt-2">
                    <input type="radio" id="productratingRadio4" name="productratingRadio1" class="form-check-input">
                    <label class="form-check-label" for="productratingRadio4">1 <i class="mdi mdi-star text-warning"></i></label>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-9">
      <div class="card">
        <div class="card-body">
          <div>
            <div class="row">
              <div class="col-md-6">
                <div>
                  <h5>Clothes & Accessories</h5>
                  <ol class="breadcrumb p-0 bg-transparent mb-2">
                    <li class="breadcrumb-item"><a href="javascript: void(0);">Fashion</a></li>
                    <li class="breadcrumb-item"><a href="javascript: void(0);">Clothing</a></li>
                    <li class="breadcrumb-item active">T-shirts</li>
                  </ol>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-inline float-md-end">
                  <div class="search-box ms-2">
                    <div class="position-relative">
                      <input type="text" class="form-control rounded" placeholder="Search..." (input)="searchFilter($event)">
                      <i class="mdi mdi-magnify search-icon"></i>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <ul class="list-inline my-3 ecommerce-sortby-list">
              <li class="list-inline-item"><span class="fw-medium font-family-secondary">Sort by:</span></li>
              <li class="list-inline-item active"><a href="javascript: void(0);">Popularity</a></li>
              <li class="list-inline-item"><a href="javascript: void(0);">Newest</a></li>
              <li class="list-inline-item"><a href="javascript: void(0);">Discount</a></li>
            </ul>

            <div class="row g-0">
              @for(product of products;track $index){
              <div class="col-xl-4 col-sm-6">
                <div class="product-box">
                  <div class="product-img">
                    <div class="product-ribbon badge bg-{{product.discolor}}">
                      {{product.discount}}
                    </div>
                    <div class="product-like">
                      <a href="javascript: void(0);">
                        <i class="mdi mdi-heart-outline"></i>
                      </a>
                    </div>
                    <a [routerLink]="['/ecommerce/products',product.id]"> <img src="{{product.images[0]}}" alt="" class="img-fluid mx-auto d-block">
                    </a>
                  </div>

                  <div class="text-center">
                    <p class="font-size-12 mb-1">{{product.category}}</p>
                    <h5 class="font-size-15"><a href="javascript: void(0);" class="text-dark">{{product.name}}</a>
                    </h5>
                    <h5 class="mt-3 mb-0"><span class="text-muted me-2"><del>$ {{product.oriRate}}</del></span>$
                      {{product.disRate}}</h5>
                  </div>
                </div>
              </div>
              }
            </div>

            <div class="row mt-4">
              <div class="col-sm-6">
                <div>
                  <p class="mb-sm-0 mt-2">Page <span class="fw-bold">2</span> Of <span class="fw-bold">113</span></p>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-sm-end">
                  <ngb-pagination class="pagination-rounded d-flex justify-content-center" [collectionSize]="6" page="1" [maxSize]="1" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->

</div> <!-- container-fluid -->