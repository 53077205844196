<div class="topnav">
  <div class="container-fluid">
    <nav class="navbar navbar-light navbar-expand-lg topnav-menu">

      <div class="collapse navbar-collapse" id="topnav-menu-content">
        <ul class="navbar-nav">

          @for(item of menuItems;track $index){
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle arrow-none" href="javascript: void(0);" [routerLink]="item.link" id="topnav-components" role="button" (click)="onMenuClick($event)">
              <i class="{{ item.icon }} me-2"></i>{{ item.label | translate }}
              @if(item.subItems){<div class="arrow-down">
              </div>}
            </a>
            @if(hasItems(item)){
            <div class="dropdown-menu row" aria-labelledby="topnav-dashboard" id="navmenu">
              @for(subitem of item.subItems;track $index){
                @if(hasItems(subitem)){
                  <div class="dropdown">
                    <a class="dropdown-item" (click)="onMenuClick($event)">{{ subitem.label | translate}}
                      <div class="arrow-down"></div>
                    </a>
                    <div class="dropdown-menu">
                      <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                        <a class="dropdown-item side-nav-link-ref" [routerLink]="subSubitem.link" routerLinkActive="active">{{ subSubitem.label | translate}}</a>
                      </ng-template>
                    </div>
                  </div>
                }@else {
                  <a class="col dropdown-item side-nav-link-ref" [routerLink]="subitem.link" routerLinkActive="active">{{subitem.label | translate}}</a>
                }
            
              }
            </div>
            }
          </li>
          }
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle arrow-none" href="javascript: void(0);" id="topnav-layout" role="button" (click)="onMenuClick($event)">
              <i class="ri-layout-3-line me-2"></i>{{'MENUITEMS.LAYOUTS.TEXT' | translate}} <div class="arrow-down">
              </div>
            </a>
            <div class="dropdown-menu dropdown-menu-end" aria-labelledby="topnav-layout">
              <a href="javascript: void(0);" class="dropdown-item" (click)="changeLayout('vertical')">{{ 'MENUITEMS.LAYOUTS.LIST.VERTICAL' | translate}}</a>
              <a href="javascript: void(0);" class="dropdown-item" (click)="topbarLight()">{{ 'MENUITEMS.LAYOUTS.LIST.LIGHTTOPBAR' | translate}}</a>
              <a href="javascript: void(0);" class="dropdown-item" (click)="boxedWidth()">{{ 'MENUITEMS.LAYOUTS.LIST.BOXED' | translate}}</a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>