<div class="container-fluid">
  <app-pagetitle title="Dashboard" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <div class="row">
    <div class="col-xl-8">
      <div class="row">
        @for(stat of statData;track $index){
        <div class="col-md-4">
          <app-stat [title]="stat.title" [value]="stat.value" [icon]="stat.icon"></app-stat>
        </div>
        }
      </div>
      <!-- end row -->

      <div class="card">
        <div class="card-body pb-1">
          <div class="float-end d-none d-md-inline-block">
            <div class="btn-group mb-2">
              <button type="button" class="btn btn-sm btn-light">Today</button>
              <button type="button" class="btn btn-sm btn-light active">Weekly</button>
              <button type="button" class="btn btn-sm btn-light">Monthly</button>
            </div>
          </div>
          <h4 class="card-title mb-4">Revenue Analytics</h4>
          <div>
            <apx-chart dir="ltr" class="apex-charts" [series]="revenueChart.series" [dataLabels]="revenueChart.dataLabels" [chart]="revenueChart.chart" [legend]="revenueChart.legend" [colors]="revenueChart.colors" [labels]="revenueChart.labels" [stroke]="revenueChart.stroke" [plotOptions]="revenueChart.plotOptions">
            </apx-chart>
          </div>
        </div>

        <div class="card-body border-top text-center">
          <div class="row">
            <div class="col-sm-4">
              <div class="d-inline-flex">
                <h5 class="me-2">$12,253</h5>
                <div class="text-success">
                  <i class="mdi mdi-menu-up font-size-14"> </i>2.2 %
                </div>
              </div>
              <p class="text-muted text-truncate mb-0">This month</p>
            </div>

            <div class="col-sm-4">
              <div class="mt-4 mt-sm-0">
                <p class="mb-2 text-muted text-truncate"><i class="mdi mdi-circle text-primary font-size-10 me-1"></i>
                  This Year :</p>
                <div class="d-inline-flex">
                  <h5 class="mb-0 me-2">$ 34,254</h5>
                  <div class="text-success">
                    <i class="mdi mdi-menu-up font-size-14"> </i>2.1 %
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="mt-4 mt-sm-0">
                <p class="mb-2 text-muted text-truncate"><i class="mdi mdi-circle text-success font-size-10 me-1"></i>
                  Previous Year :</p>
                <div class="d-inline-flex">
                  <h5 class="mb-0">$ 32,695</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-4">
      <div class="card">
        <div class="card-body">
          <div class="float-end">
            <select class="custom-select custom-select-sm">
              <option selected>Apr</option>
              <option value="1">Mar</option>
              <option value="2">Feb</option>
              <option value="3">Jan</option>
            </select>
          </div>
          <h4 class="card-title mb-4">Sales Analytics</h4>

          <apx-chart dir="ltr" class="apex-charts" [series]="salesAnalytics.series" [chart]="salesAnalytics.chart" [legend]="salesAnalytics.legend" [colors]="salesAnalytics.colors" [labels]="revenueChart.labels" [dataLabels]="salesAnalytics.dataLabels" [plotOptions]="salesAnalytics.plotOptions">
          </apx-chart>
          <div class="row">
            <div class="col-4">
              <div class="text-center mt-4">
                <p class="mb-2 text-truncate"><i class="mdi mdi-circle text-primary font-size-10 me-1"></i> Product A
                </p>
                <h5>42 %</h5>
              </div>
            </div>
            <div class="col-4">
              <div class="text-center mt-4">
                <p class="mb-2 text-truncate"><i class="mdi mdi-circle text-success font-size-10 me-1"></i> Product B
                </p>
                <h5>26 %</h5>
              </div>
            </div>
            <div class="col-4">
              <div class="text-center mt-4">
                <p class="mb-2 text-truncate"><i class="mdi mdi-circle text-warning font-size-10 me-1"></i> Product C
                </p>
                <h5>42 %</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="dropdown float-end" ngbDropdown>
            <a href="javascript: void(0);" class="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
              <i class="mdi mdi-dots-vertical"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item">Sales Report</a>
              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item">Export Report</a>
              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item">Profit</a>
              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item">Action</a>
            </div>
          </div>

          <h4 class="card-title mb-4">Earning Reports</h4>
          <div class="text-center">
            <div class="row">
              <div class="col-sm-6">
                <div>
                  <div class="mb-3">
                    <apx-chart dir="ltr" class="apex-charts" [series]="sparklineEarning.series" [chart]="sparklineEarning.chart" [colors]="sparklineEarning.colors" [labels]="sparklineEarning.labels" [dataLabels]="sparklineEarning.dataLabels" [plotOptions]="sparklineEarning.plotOptions">
                    </apx-chart>
                  </div>

                  <p class="text-muted text-truncate mb-2">Weekly Earnings</p>
                  <h5 class="mb-0">$2,523</h5>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="mt-5 mt-sm-0">
                  <div class="mb-3">
                    <apx-chart dir="ltr" class="apex-charts" [series]="sparklineMonthly.series" [chart]="sparklineMonthly.chart" [colors]="sparklineMonthly.colors" [labels]="sparklineMonthly.labels" [dataLabels]="sparklineMonthly.dataLabels" [plotOptions]="sparklineMonthly.plotOptions">
                    </apx-chart>
                  </div>

                  <p class="text-muted text-truncate mb-2">Monthly Earnings</p>
                  <h5 class="mb-0">$11,235</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->

  <div class="row">
    <div class="col-lg-4">
      <div class="card">
        <div class="card-body">
          <div class="dropdown float-end" ngbDropdown>
            <a href="javascript: void(0);" class="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
              <i class="mdi mdi-dots-vertical"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item">Sales Report</a>
              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item">Export Report</a>
              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item">Profit</a>
              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item">Action</a>
            </div>
          </div>

          <h4 class="card-title mb-3">Sources</h4>

          <div>
            <div class="text-center">
              <p class="mb-2">Total sources</p>
              <h4>$ 7652</h4>
              <div class="text-success">
                <i class="mdi mdi-menu-up font-size-14"> </i>2.2 %
              </div>
            </div>

            <div class="table-responsive mt-4">
              <table class="table table-hover mb-0 table-centered table-nowrap">
                <tbody>
                  <tr>
                    <td style="width: 60px;">
                      <div class="avatar-xs">
                        <div class="avatar-title rounded-circle bg-light">
                          <img src="assets/images/companies/img-1.png" alt="" height="20">
                        </div>
                      </div>
                    </td>

                    <td>
                      <h5 class="font-size-14 mb-0">Source 1</h5>
                    </td>
                    <td>
                      <div id="spak-chart1">
                        <apx-chart dir="ltr" class="apex-charts" [series]="spark1.series" [chart]="spark1.chart" [colors]="spark1.colors" [stroke]="spark1.stroke" [tooltip]="spark1.tooltip">
                        </apx-chart>
                      </div>
                    </td>
                    <td>
                      <p class="text-muted mb-0">$ 2478</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="avatar-xs">
                        <div class="avatar-title rounded-circle bg-light">
                          <img src="assets/images/companies/img-2.png" alt="" height="20">
                        </div>
                      </div>
                    </td>
                    <td>
                      <h5 class="font-size-14 mb-0">Source 2</h5>
                    </td>

                    <td>
                      <div id="spak-chart2">
                        <apx-chart dir="ltr" class="apex-charts" [series]="spark2.series" [chart]="spark2.chart" [colors]="spark2.colors" [stroke]="spark2.stroke" [tooltip]="spark2.tooltip">
                        </apx-chart>
                      </div>
                    </td>
                    <td>
                      <p class="text-muted mb-0">$ 2625</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="avatar-xs">
                        <div class="avatar-title rounded-circle bg-light">
                          <img src="assets/images/companies/img-3.png" alt="" height="20">
                        </div>
                      </div>
                    </td>
                    <td>
                      <h5 class="font-size-14 mb-0">Source 3</h5>
                    </td>
                    <td>
                      <div id="spak-chart3">
                        <apx-chart dir="ltr" class="apex-charts" [series]="spark3.series" [chart]="spark3.chart" [colors]="spark3.colors" [stroke]="spark3.stroke" [tooltip]="spark3.tooltip">
                        </apx-chart>
                      </div>
                    </td>
                    <td>
                      <p class="text-muted mb-0">$ 2856</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="text-center mt-4">
              <a href="javascript: void(0);" class="btn btn-primary btn-sm">View more</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card">
        <div class="card-body">
          <div class="dropdown float-end" ngbDropdown>
            <a href="javascript: void(0);" class="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
              <i class="mdi mdi-dots-vertical"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item">Sales Report</a>
              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item">Export Report</a>
              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item">Profit</a>
              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item">Action</a>
            </div>
          </div>

          <h4 class="card-title mb-4">Recent Activity Feed</h4>

          <ngx-simplebar style="height: 330px;">
            <ul class="list-unstyled activity-wid">
              <li class="activity-list">
                <div class="activity-icon avatar-xs">
                  <span class="avatar-title bg-primary-subtle text-primary text-primary rounded-circle">
                    <i class="ri-edit-2-fill"></i>
                  </span>
                </div>
                <div>
                  <div>
                    <h5 class="font-size-13">28 Apr, 2020 <small class="text-muted">12:07 am</small></h5>
                  </div>

                  <div>
                    <p class="text-muted mb-0">Responded to need “Volunteer Activities”</p>
                  </div>
                </div>
              </li>
              <li class="activity-list">
                <div class="activity-icon avatar-xs">
                  <span class="avatar-title bg-primary-subtle text-primary text-primary rounded-circle">
                    <i class="ri-user-2-fill"></i>
                  </span>
                </div>
                <div>
                  <div>
                    <h5 class="font-size-13">21 Apr, 2020 <small class="text-muted">08:01 pm</small></h5>
                  </div>

                  <div>
                    <p class="text-muted mb-0">Added an interest “Volunteer Activities”</p>
                  </div>
                </div>
              </li>
              <li class="activity-list">
                <div class="activity-icon avatar-xs">
                  <span class="avatar-title bg-primary-subtle text-primary text-primary rounded-circle">
                    <i class="ri-bar-chart-fill"></i>
                  </span>
                </div>
                <div>
                  <div>
                    <h5 class="font-size-13">17 Apr, 2020 <small class="text-muted">09:23 am</small></h5>
                  </div>

                  <div>
                    <p class="text-muted mb-0">Joined the group “Boardsmanship Forum”</p>
                  </div>
                </div>
              </li>
              <li class="activity-list">
                <div class="activity-icon avatar-xs">
                  <span class="avatar-title bg-primary-subtle text-primary text-primary rounded-circle">
                    <i class="ri-mail-fill"></i>
                  </span>
                </div>
                <div>
                  <div>
                    <h5 class="font-size-13">11 Apr, 2020 <small class="text-muted">05:10 pm</small></h5>
                  </div>

                  <div>
                    <p class="text-muted mb-0">Responded to need “In-Kind Opportunity”</p>
                  </div>
                </div>
              </li>
              <li class="activity-list">
                <div class="activity-icon avatar-xs">
                  <span class="avatar-title bg-primary-subtle text-primary text-primary rounded-circle">
                    <i class="ri-calendar-2-fill"></i>
                  </span>
                </div>
                <div>
                  <div>
                    <h5 class="font-size-13">07 Apr, 2020 <small class="text-muted">12:47 pm</small></h5>
                  </div>

                  <div>
                    <p class="text-muted mb-0">Created need “Volunteer Activities”</p>
                  </div>
                </div>
              </li>
              <li class="activity-list">
                <div class="activity-icon avatar-xs">
                  <span class="avatar-title bg-primary-subtle text-primary text-primary rounded-circle">
                    <i class="ri-edit-2-fill"></i>
                  </span>
                </div>
                <div>
                  <div>
                    <h5 class="font-size-13">05 Apr, 2020 <small class="text-muted">03:09 pm</small></h5>
                  </div>

                  <div>
                    <p class="text-muted mb-0">Attending the event “Some New Event”</p>
                  </div>
                </div>
              </li>
              <li class="activity-list">
                <div class="activity-icon avatar-xs">
                  <span class="avatar-title bg-primary-subtle text-primary text-primary rounded-circle">
                    <i class="ri-user-2-fill"></i>
                  </span>
                </div>
                <div>
                  <div>
                    <h5 class="font-size-13">02 Apr, 2020 <small class="text-muted">12:07 am</small></h5>
                  </div>

                  <div>
                    <p class="text-muted mb-0">Responded to need “In-Kind Opportunity”</p>
                  </div>
                </div>
              </li>
            </ul>
          </ngx-simplebar>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card">
        <div class="card-body">
          <div class="dropdown float-end" ngbDropdown>
            <a href="javascript: void(0);" class="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
              <i class="mdi mdi-dots-vertical"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item">Sales Report</a>
              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item">Export Report</a>
              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item">Profit</a>
              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item">Action</a>
            </div>
          </div>

          <h4 class="card-title mb-4">Revenue by Locations</h4>
          <div style="height: 196px;" leaflet [leafletOptions]="options">
          </div>
          <div class="row justify-content-center">
            <div class="col-xl-5 col-md-6">
              <div class="mt-2">
                <div class="clearfix py-2">
                  <h5 class="float-end font-size-16 m-0">$ 2542</h5>
                  <p class="text-muted mb-0 text-truncate">California :</p>

                </div>
                <div class="clearfix py-2">
                  <h5 class="float-end font-size-16 m-0">$ 2245</h5>
                  <p class="text-muted mb-0 text-truncate">Nevada :</p>
                </div>
              </div>
            </div>
            <div class="col-xl-5 offset-xl-1 col-md-6">
              <div class="mt-2">
                <div class="clearfix py-2">
                  <h5 class="float-end font-size-16 m-0">$ 2156</h5>
                  <p class="text-muted mb-0 text-truncate">Montana :</p>

                </div>
                <div class="clearfix py-2">
                  <h5 class="float-end font-size-16 m-0">$ 1845</h5>
                  <p class="text-muted mb-0 text-truncate">Texas :</p>

                </div>
              </div>
            </div>
          </div>
          <div class="text-center mt-4">
            <a href="javascript: void(0);" class="btn btn-primary btn-sm">Learn more</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->

  <div class="row">
    <div class="col-lg-4">
      <div class="card">
        <div class="card-body border-bottom">

          <div class="user-chat-border">
            <div class="row">
              <div class="col-md-5 col-9">
                <h5 class="font-size-15 mb-1">Frank Vickery</h5>
                <p class="text-muted mb-0"><i class="mdi mdi-circle text-success align-middle ms-1"></i> Active now</p>
              </div>
              <div class="col-md-7 col-3">
                <ul class="list-inline user-chat-nav text-end mb-0">
                  <li class="list-inline-item">
                    <div class="dropdown" ngbDropdown>
                      <button class="btn nav-btn dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                        <i class="mdi mdi-magnify"></i>
                      </button>
                      <div class="dropdown-menu dropdown-menu-end dropdown-menu-md p-0" ngbDropdownMenu>
                        <form class="p-2">
                          <div class="search-box">
                            <div class="position-relative">
                              <input type="text" class="form-control rounded bg-light border-0" placeholder="Search...">
                              <i class="mdi mdi-magnify search-icon"></i>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </li>
                  <li class="list-inline-item d-none d-sm-inline-block">
                    <div class="dropdown" ngbDropdown>
                      <button class="btn nav-btn dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                        <i class="mdi mdi-cog"></i>
                      </button>
                      <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                        <a class="dropdown-item" href="#">View Profile</a>
                        <a class="dropdown-item" href="#">Clear chat</a>
                        <a class="dropdown-item" href="#">Muted</a>
                        <a class="dropdown-item" href="#">Delete</a>
                      </div>
                    </div>
                  </li>

                  <li class="list-inline-item">
                    <div class="dropdown" ngbDropdown>
                      <button class="btn nav-btn dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                        <i class="mdi mdi-dots-horizontal"></i>
                      </button>
                      <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                        <a class="dropdown-item" href="#">Action</a>
                        <a class="dropdown-item" href="#">Another action</a>
                        <a class="dropdown-item" href="#">Something else</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="chat-widget">
            <div class="chat-conversation">
              <ngx-simplebar style="height: 292px;">
                @for(data of chatData;track $index){
                <ul class="list-unstyled mb-0 pr-3" [ngClass]="{ 'right': data.align === 'right' }">
                  @if(data.text){
                  <li>
                    <div class="chat-day-title">
                      <span class="title">{{data.text}}</span>
                    </div>
                  </li>
                  }@else {
                  <li>
                    <div class="conversation-list">
                      <div class="chat-avatar">
                        @if(data.image){
                        <img src="{{data.image}}" alt="">
                        }
                      </div>
                      <div class="ctext-wrap">
                        <div class="conversation-name">{{data.name}}</div>
                        <div class="ctext-wrap-content">
                          <p class="mb-0">
                            {{data.message}}
                          </p>
                        </div>
                        <p class="chat-time mb-0"><i class="mdi mdi-clock-outline align-middle ms-1"></i> {{data.time}}
                        </p>
                      </div>
                    </div>
                  </li>
                  }
                </ul>
                }
              </ngx-simplebar>
            </div>
          </div>
        </div>
        <div class="p-3 chat-input-section border-top">
          <form (ngSubmit)="messageSave()" [formGroup]="formData" class="row">
            <div class="col">
              <div class="position-relative">
                <input type="text" class="form-control chat-input" placeholder="Enter Message..." formControlName="message" [ngClass]="{'is-invalid': chatSubmit && form.message.errors}">
                @if(chatSubmit && form.message.errors){
                <div class="invalid-feedback">
                  @if(form.message.errors.required){
                  <span>This value is required.</span>
                  }
                </div>
                }
              </div>
            </div>
            <div class="col-auto">
              <button type="submit" class="btn btn-primary chat-send w-md waves-effect waves-light"><span class="d-none d-sm-inline-block">Send</span> <i class="mdi mdi-send ms-1"></i></button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-lg-8">
      <div class="card">
        <div class="card-body">
          <div class="dropdown float-end" ngbDropdown>
            <a href="javascript: void(0);" class="dropdown-toggle arrow-none card-drop" ngbDropdownToggle data-toggle="dropdown" aria-expanded="false">
              <i class="mdi mdi-dots-vertical"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item">Sales Report</a>
              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item">Export Report</a>
              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item">Profit</a>
              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item">Action</a>
            </div>
          </div>
          <h4 class="card-title mb-4">Latest Transactions</h4>
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div id="tickets-table_filter" class="dataTables_filter text-md-end"><label class="d-inline-flex align-items-center">Search:
                  <input type="text" name="searchTerm" class="form-control form-control-sm ms-2" aria-controls="tickets-table" [(ngModel)]="term" /></label></div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-centered">
              <thead class="table-light">
                <tr>
                  <th style="width: 26px;" class="sorting_disabled" rowspan="1" colspan="1">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="ordercheck">
                      <label class="form-check-label mb-0" for="ordercheck">&nbsp;</label>
                    </div>
                  </th>
                  <th>Order ID</th>
                  <th>Date</th>
                  <th>Billing Name</th>
                  <th>Total</th>
                  <th>Payment Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                @for(item of transactions | filterBy:['billingname','paymentstatus']:term;track $index){
                <tr>
                  <td class="dtr-control">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="ordercheck10">
                      <label class="form-check-label mb-0" for="ordercheck10">&nbsp;</label>
                    </div>
                  </td>
                  <td>{{item.orderid}}</td>
                  <td>{{item.date}}</td>
                  <td>{{item.billingname}}</td>
                  <td>{{item.total}}</td>
                  <td>
                    <div class="badge font-size-12" [ngClass]="{'bg-danger-subtle text-danger': item.paymentstatus === 'Chargeback',
                      'bg-success-subtle text-success':item.paymentstatus === 'Paid',
                      'bg-warning-subtle text-warning': item.paymentstatus === 'Unpaid'}">{{item.paymentstatus}}
                    </div>
                  </td>
                  <td>
                    <a href="javascript:void(0);" class="me-3 text-primary" ngbTooltip="Edit"><i class="mdi mdi-pencil font-size-18"></i></a>
                    <a href="javascript:void(0);" class="text-danger" ngbTooltip="Delete"><i class="mdi mdi-trash-can font-size-18"></i></a>
                  </td>
                </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</div>