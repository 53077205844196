<div class="container-fluid">
  <app-pagetitle title="Form Wizard" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Basic pills Wizard</h4>
          <div id="basic-pills-wizard" class="twitter-bs-wizard">
            <ng-stepper #cdkStepper [linear]="true" class="twitter-bs-wizard-nav nav nav-pills nav-justified">
                <cdk-step [optional]="false" class="nav-item">
                  <ng-template cdkStepLabel>
                    <a href="javascript: void(0);" class="nav-link active" data-toggle="tab">
                        <span class="step-number">01</span>
                        <span class="step-title">Seller Details</span>
                    </a>
                    </ng-template>
                    <section *ngIf="cdkStepper.selectedIndex === 0">
                      <div class="tab-pane active" id="seller-details">
                        <form>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label class="form-label" for="basicpill-firstname-input">First name</label>
                                        <input type="text" class="form-control" id="basicpill-firstname-input">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label class="form-label" for="basicpill-lastname-input">Last name</label>
                                        <input type="text" class="form-control" id="basicpill-lastname-input">
                                    </div>
                                </div>
                            </div>
        
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label class="form-label" for="basicpill-phoneno-input">Phone</label>
                                        <input type="text" class="form-control" id="basicpill-phoneno-input">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label class="form-label" for="basicpill-email-input">Email</label>
                                        <input type="email" class="form-control" id="basicpill-email-input">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="mb-3">
                                        <label class="form-label" for="basicpill-address-input">Address</label>
                                        <textarea id="basicpill-address-input" class="form-control" rows="2"></textarea>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <ul class="pager wizard twitter-bs-wizard-pager-link">
                      <li class="previous disabled" aria-disabled="true"><button class="btn btn-primary" cdkStepperPrevious>Previous</button></li>
                      <li class="next" aria-disabled="false"><button class="btn btn-primary" cdkStepperNext>Next</button></li>
                  </ul>
                    </section>
                </cdk-step>

                <cdk-step [optional]="true" class="nav-item">
                  <ng-template cdkStepLabel>
                    <a href="javascript: void(0);" class="nav-link" data-toggle="tab">
                        <span class="step-number">02</span>
                        <span class="step-title">Company Document</span>
                    </a>
                    </ng-template>
                    <section *ngIf="cdkStepper.selectedIndex === 1">
                      <div class="tab-pane" id="company-document">
                        <div>
                            <form>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label class="form-label" for="basicpill-pancard-input">PAN Card</label>
                                            <input type="text" class="form-control" id="basicpill-pancard-input">
                                        </div>
                                    </div>
        
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label class="form-label" for="basicpill-vatno-input">VAT/TIN No.</label>
                                            <input type="text" class="form-control" id="basicpill-vatno-input">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label class="form-label" for="basicpill-cstno-input">CST No.</label>
                                            <input type="text" class="form-control" id="basicpill-cstno-input">
                                        </div>
                                    </div>
        
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label class="form-label" for="basicpill-servicetax-input">Service Tax No.</label>
                                            <input type="text" class="form-control" id="basicpill-servicetax-input">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label class="form-label" for="basicpill-companyuin-input">Company UIN</label>
                                            <input type="text" class="form-control" id="basicpill-companyuin-input">
                                        </div>
                                    </div>
        
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label class="form-label" for="basicpill-declaration-input">Declaration</label>
                                            <input type="text" class="form-control" id="basicpill-declaration-input">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <ul class="pager wizard twitter-bs-wizard-pager-link">
                      <li class="previous" aria-disabled="true"><button class="btn btn-primary" cdkStepperPrevious>Previous</button></li>
                      <li class="next" aria-disabled="false"><button class="btn btn-primary" cdkStepperNext>Next</button></li>
                  </ul>
                    </section>
                </cdk-step>
    
                <cdk-step [optional]="true" class="nav-item">
                  <ng-template cdkStepLabel>
                    <a href="javascript: void(0);" class="nav-link" data-toggle="tab">
                        <span class="step-number">03</span>
                        <span class="step-title">Bank Details</span>
                    </a>
                    </ng-template>
                    <section *ngIf="cdkStepper.selectedIndex === 2">
                      <div class="tab-pane" id="bank-detail">
                        <div>
                            <form>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label class="form-label" for="basicpill-namecard-input">Name on Card</label>
                                            <input type="text" class="form-control" id="basicpill-namecard-input">
                                        </div>
                                    </div>
        
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label>Credit Card Type</label>
                                            <select class="form-select">
                                                <option selected="">Select Card Type</option>
                                                <option value="AE">American Express</option>
                                                <option value="VI">Visa</option>
                                                <option value="MC">MasterCard</option>
                                                <option value="DI">Discover</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label class="form-label" for="basicpill-cardno-input">Credit Card Number</label>
                                            <input type="text" class="form-control" id="basicpill-cardno-input">
                                        </div>
                                    </div>
        
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label class="form-label" for="basicpill-card-verification-input">Card Verification Number</label>
                                            <input type="text" class="form-control" id="basicpill-card-verification-input">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label class="form-label" for="basicpill-expiration-input">Expiration Date</label>
                                            <input type="text" class="form-control" id="basicpill-expiration-input">
                                        </div>
                                    </div>
        
                                </div>
                            </form>
                        </div>
                    </div>
                    <ul class="pager wizard twitter-bs-wizard-pager-link">
                      <li class="previous" aria-disabled="true"><button class="btn btn-primary" cdkStepperPrevious>Previous</button></li>
                      <li class="next" aria-disabled="false"><button class="btn btn-primary" cdkStepperNext>Next</button></li>
                  </ul>
                    </section>
                </cdk-step>
                <cdk-step [optional]="true" class="nav-item">
                  <ng-template cdkStepLabel>
                    <a href="javascript: void(0);" class="nav-link" data-toggle="tab">
                        <span class="step-number">04</span>
                        <span class="step-title">Confirm Detail</span>
                    </a>
                    </ng-template>
                    <section *ngIf="cdkStepper.selectedIndex === 3">
                      <div class="tab-pane" id="confirm-detail">
                        <div class="row justify-content-center">
                            <div class="col-lg-6">
                                <div class="text-center">
                                    <div class="mb-4">
                                        <i class="mdi mdi-check-circle-outline text-success display-4"></i>
                                    </div>
                                    <div>
                                        <h5>Confirm Detail</h5>
                                        <p class="text-muted">If several languages coalesce, the grammar of the resulting</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul class="pager wizard twitter-bs-wizard-pager-link">
                      <li class="previous" aria-disabled="true"><button class="btn btn-primary" cdkStepperPrevious>Previous</button></li>
                      <li class="next disabled" aria-disabled="false"><button class="btn btn-primary" cdkStepperNext>Next</button></li>
                  </ul>
                    </section>
                </cdk-step>
            </ng-stepper>
        </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4"> Vertical Wizard</h4>
          <ng-stepper #cdkStepper [linear]="true" id="vertical-example" class="vertical-wizard">

            <cdk-step [optional]="false">
              <ng-template cdkStepLabel>
                <span class="number">1.</span>
                <span>Seller Details</span>
              </ng-template>
              <section >
                <form>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="basicpill-firstname-input">First name</label>
                        <input type="text" class="form-control" id="basicpill-firstname-input">
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="basicpill-lastname-input">Last name</label>
                        <input type="text" class="form-control" id="basicpill-lastname-input">
                      </div>
                    </div>
                  </div>
  
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="basicpill-phoneno-input">Phone</label>
                        <input type="text" class="form-control" id="basicpill-phoneno-input">
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="basicpill-email-input">Email</label>
                        <input type="email" class="form-control" id="basicpill-email-input">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="basicpill-address-input">Address</label>
                        <textarea id="basicpill-address-input" class="form-control" rows="2"></textarea>
                      </div>
                    </div>
                  </div>
                </form>
                <ul class="list-inline wizard d-flex justify-content-end mb-0">
                  <li class="previous list-inline-item" aria-disabled="true"><button class="btn btn-primary" cdkStepperPrevious>Previous</button></li>
                  <li class="next list-inline-item" aria-disabled="false"><button class="btn btn-primary" cdkStepperNext>Next</button></li>
                </ul>
              </section>
            </cdk-step>
  
            <cdk-step [optional]="true">
              <ng-template cdkStepLabel>
                <span class="number">2.</span>
                <span>Company Document</span>
              </ng-template>
              <section >
                <form>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="basicpill-pancard-input">PAN Card</label>
                        <input type="text" class="form-control" id="basicpill-pancard-input">
                      </div>
                    </div>
  
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="basicpill-vatno-input">VAT/TIN No.</label>
                        <input type="text" class="form-control" id="basicpill-vatno-input">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="basicpill-cstno-input">CST No.</label>
                        <input type="text" class="form-control" id="basicpill-cstno-input">
                      </div>
                    </div>
  
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="basicpill-servicetax-input">Service Tax No.</label>
                        <input type="text" class="form-control" id="basicpill-servicetax-input">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="basicpill-companyuin-input">Company UIN</label>
                        <input type="text" class="form-control" id="basicpill-companyuin-input">
                      </div>
                    </div>
  
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="basicpill-declaration-input">Declaration</label>
                        <input type="text" class="form-control" id="basicpill-declaration-input">
                      </div>
                    </div>
                  </div>
                </form>
  
                <ul class="list-inline wizard d-flex justify-content-end mb-0">
                  <li class="previous list-inline-item" aria-disabled="true"><button class="btn btn-primary" cdkStepperPrevious>Previous</button></li>
                  <li class="next list-inline-item" aria-disabled="false"><button class="btn btn-primary" cdkStepperNext>Next</button></li>
                </ul>
              </section>
            </cdk-step>
  
            <cdk-step [optional]="true">
              <ng-template cdkStepLabel>
                <span class="number">3.</span>
                <span>Bank Details</span>
              </ng-template>
              <section>
                <div>
                  <form>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="mb-3">
                          <label for="basicpill-namecard-input">Name on Card</label>
                          <input type="text" class="form-control" id="basicpill-namecard-input">
                        </div>
                      </div>
  
                      <div class="col-lg-6">
                        <div class="mb-3">
                          <label>Credit Card Type</label>
                          <select class="form-select">
                            <option selected>Select Card Type</option>
                            <option value="AE">American Express</option>
                            <option value="VI">Visa</option>
                            <option value="MC">MasterCard</option>
                            <option value="DI">Discover</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="mb-3">
                          <label for="basicpill-cardno-input">Credit Card Number</label>
                          <input type="text" class="form-control" id="basicpill-cardno-input">
                        </div>
                      </div>
  
                      <div class="col-lg-6">
                        <div class="mb-3">
                          <label for="basicpill-card-verification-input">Card Verification Number</label>
                          <input type="text" class="form-control" id="basicpill-card-verification-input">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="mb-3">
                          <label for="basicpill-expiration-input">Expiration Date</label>
                          <input type="text" class="form-control" id="basicpill-expiration-input">
                        </div>
                      </div>
  
                    </div>
                  </form>
                </div>
  
                <ul class="list-inline wizard d-flex justify-content-end mb-0">
                  <li class="previous list-inline-item" aria-disabled="true"><button class="btn btn-primary" cdkStepperPrevious>Previous</button></li>
                  <li class="next list-inline-item" aria-disabled="false"><button class="btn btn-primary" cdkStepperNext>Next</button></li>
                </ul>
              </section>
            </cdk-step>
  
            <cdk-step [optional]="true">
              <ng-template cdkStepLabel>
                <span class="number">4.</span>
                <span>Confirm Detail</span>
              </ng-template>
              <section >
                <div class="row justify-content-center">
                  <div class="col-lg-6">
                    <div class="text-center">
                      <div class="mb-4">
                        <i class="mdi mdi-check-circle-outline text-success display-4"></i>
                      </div>
                      <div>
                        <h5>Confirm Detail</h5>
                        <p class="text-muted">If several languages coalesce, the grammar of the resulting</p>
                      </div>
                    </div>
                  </div>
                </div>
  
                <ul class="list-inline wizard d-flex justify-content-end mb-0">
                  <li class="previous list-inline-item" aria-disabled="true"><button class="btn btn-primary" cdkStepperPrevious>Previous</button></li>
                  <li class="next list-inline-item" aria-disabled="false"><button class="btn btn-primary" cdkStepperNext>Finish</button></li>
                </ul>
              </section>
            </cdk-step>
  
          </ng-stepper>
        </div>
      </div>
    </div>
  </div>
</div>